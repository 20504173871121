<template>
  <div class="container correction" v-if="showCorrection">
    <div class="d-flex flex-column">

      <div class="d-flex gap-xl flex-wrap">
        <div class="draggable">
          <h4 class="mb-1">{{ question.text }}</h4>
          <v-card outlined v-for="(choice, index) in formResponse.responses[slideIndex]" :key="index" class="pa-3 card" :class="isCorrect ? 'success--text' : correctionColor(index)">
            <pre class="mb-0">{{ choice }}</pre>
          </v-card>
        </div>
        <v-divider vertical v-if="!isCorrect" />
        <div class="draggable" v-if="!isCorrect">
          Correction :
          <v-card outlined v-for="(choice, index) in question.goodResponse" :key="index" class="pa-3 card info--text">
            <pre class="mb-0">{{ choice }}</pre>
          </v-card>
        </div>
      </div>
    </div>
  </div>

  <!-- user -->
  <div class="container" v-else-if="profile && profile.role === 'User'">
    <div class="d-flex flex-column">
      <h4 class="mb-1">{{ question.text }}</h4>

      <draggable class="draggable" v-model="response" :disabled="disabled" @change="formResponse.lastUpdateDate = new Date().getTime()">
        <v-card outlined v-for="(choice, index) in response" :key="index" class="pa-3 card" :class="disabled ? 'disabled' : ''">
          <pre class="mb-0">{{ choice }}</pre>
        </v-card>
      </draggable>
    </div>
  </div>

  <!-- preview -->
  <div class="container" v-else-if="preview">
    <div class="d-flex flex-column">
      <h4 class="mb-1">{{ question.text }}</h4>

      <draggable class="draggable">
        <v-card outlined v-for="(choice, index) in previewValue" :key="index" class="pa-3 card">
          <pre class="mb-0">{{ choice }}</pre>
        </v-card>
      </draggable>
    </div>
  </div>

  <!-- admin -->
  <div class="container" v-else
    @mouseover="addToRightClick" @mouseleave="delToRightClick"
  >
    <div class="noteContainer mb-3">
      <v-text-field hide-details class="ma-0 pa-0" placeholder="Énoncé" v-model="question.text" />
      <div class="note">
        /<span><v-text-field dense type="number" hide-details class="ma-0" @blur="testNote" v-model="question.points" /></span>
      </div>
    </div>
    <draggable class="draggable" v-model="question.goodResponse">
      <v-card outlined v-for="(choice, index) in question.goodResponse" :key="index" class="pa-3 card"
        @mouseover="addItemToRightClick(index)" @mouseleave="delItemToRightClick"
      >
        <v-textarea v-model="question.goodResponse[index]" rows="1" auto-grow hide-details class="mt-0 pt-0" placeholder="Réponse" />
      </v-card>
    </draggable>
  </div>
</template>

<script>
import Correction from '@/assets/functions/corrections'
import testNote from '@/assets/functions/corrections/testNote'
import draggable from 'vuedraggable'

export default {
  props: ['profile', 'question', 'preview', 'rightClickItems', 'profile', 'formResponse', 'slideIndex', 'disabled', 'showCorrection'],
  components: { draggable },
  data() {
    return {
      previewValue: [],
      isCorrect: false,

      response: []
    }
  },
  watch: {
    preview(newVal) {
      if (newVal) {
        this.previewValue = [].concat(this.question.goodResponse).sort((a, b) => 0.5 - Math.random())
      }
    },
    response(newVal) {
      this.formResponse.responses[this.slideIndex] = newVal
    },
    showCorrection() {
      this.runCorrection()
    }
  },
  created() {
    if (this.profile && this.profile.role === 'User') {
      if (!this.formResponse.responses[this.slideIndex]) {
        this.response = [].concat(this.question.goodResponse).sort((a, b) => 0.5 - Math.random())
      } else {
        this.response = this.formResponse.responses[this.slideIndex]
      }
    } else if (this.preview) {
      this.previewValue = [].concat(this.question.goodResponse).sort((a, b) => 0.5 - Math.random())
    }
  },
  mounted() {
    if (this.showCorrection) {
      this.runCorrection()
    }
  },
  methods: {
    addToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[10] = {
          text: 'Ajouter une réponse',
          method: () => { this.question.goodResponse.push(''); this.$forceUpdate() }
        }
      }
    },
    delToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[10] = null
      }
    },
    addItemToRightClick(i) {
      if (this.rightClickItems) {
        this.rightClickItems[11] = {
          text: 'Supprimé la réponse',
          method: () => { this.question.goodResponse.splice(i,1); this.$forceUpdate() }
        }
      }
    },
    delItemToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[11] = null
      }
    },

    runCorrection() {
      if (this.formResponse.responses[this.slideIndex]) {
        this.isCorrect = Correction('orderTheList', this.formResponse.responses[this.slideIndex], this.question.goodResponse)
      }
    },
    correctionColor(index) {
      if (this.formResponse.responses[this.slideIndex][index] === this.question.goodResponse[index]) {
        return 'success--text'
      } else {
        return 'error--text'
      }
    },

    testNote() {
      this.question.points = testNote(this.question.points)
    }
  }
}
</script>

<style scoped lang="scss">
.noteContainer {
  display: grid;
  grid-template-columns: auto 100px;
  .note {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    > span {
      margin: 0;
      padding-left: 6px;
      width: 60px;
    }
  }
}


.card {
  text-align: center;
  transition: 250ms;
  padding: 0 12px;
  cursor: default;

  &:not(.disabled) {
    &:hover {
      border-color: var(--v-primary-base);
    }
    &:focus,
    &:has(:focus) {
      border: solid 2px var(--v-primary-base);
    }
  }
  &::before {
    opacity: 0;
  }

  &.disabled {
    color: var(--v-textContrast-base)!important;
    border-color: var(--v-textContrast-base)!important;
  }
}
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.draggable {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  max-width: 100%;
  > * {
    cursor: grab;
    width: 100%;
  }
}

.correction {
  pointer-events: none;
}
.success--text {
  border-color: var(--v-success-base);
}
.error--text {
  border-color: var(--v-error-base);
}
.info--text {
  border-color: var(--v-info-base);
}
</style>