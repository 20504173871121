<template>
    <v-dialog v-model="displayed" max-width="1000px" persistent>
        <v-card class="pa-2">
            <v-btn color="secondary" style="position:absolute; right:0; top:0;" icon @click="close">X</v-btn>
            <v-card-text class="d-flex flex-column pa-3 pt-0">
                <v-form class="multi-col-validation mt-6">
                    <v-row>
                        <!-- left -->
                        <v-col cols="12" md="4" class="d-flex flex-column justify-center">
                            <!-- sender -->
                            <div v-if="profile.role != 'User'" class="mb-5" width="100%">
                                <h2>Émetteur :</h2>
                                <v-btn outlined @click="$refs.dialogGetSender.open()" class="text-left pa-3" style="width:100%;height:unset!important;text-transform:unset">
                                    <UserCard v-if="currentSender" :profile="currentSender" :mailAndJob="true"></UserCard>
                                    <v-icon v-else>mdi-plus</v-icon>
                                </v-btn>
                                <DialogNewConversation with="contacts" :blacklist="[]" @convSelected="(user) => this.currentSender = user" ref="dialogGetSender" :profile="profile"></DialogNewConversation>
                            </div>
                            <div width="100%">
                                <h2>Envoyé à :</h2>
                                <v-btn outlined @click="$refs.dialogGetReceiver.open()" class="text-left pa-3" style="width:100%;height:unset!important;text-transform:unset">
                                    <!-- all -->
                                    <div v-if="currentReceiver === 'all'" class="d-flex align-center flex-wrap">
                                        <UserAvatar :group="true"></UserAvatar>
                                        <b class="ma-0 mx-3" width="100px">La room</b>
                                    </div>
                                    <!-- groups -->
                                    <div v-else-if="currentReceiver && currentReceiver.members" class="d-flex align-center">
                                        <UserAvatar :group="true"></UserAvatar>
                                        <b class="ma-0 mx-3" v-text="currentReceiver.name"></b>
                                    </div>
                                    <!-- contact & profile -->
                                    <div v-else-if="currentReceiver">
                                        <UserCard :profile="currentReceiver" :mailAndJob="currentReceiver.domain ? true : false"></UserCard>
                                    </div>
                                </v-btn>
                                <DialogNewConversation :with="profile.role === 'User' ? 'contacts' : 'users all groups'" :blacklist="[]" @convSelected="(user) => this.currentReceiver = user" ref="dialogGetReceiver" :profile="profile"></DialogNewConversation>
                            </div>
                            <v-btn color="primary" class="px-12 mt-8 mx-auto" @click="SendMail">Envoyer le mail</v-btn>
                        </v-col>
                        <!-- right -->
                        <v-col cols="12" md="8">
                            <!-- date + hours -->
                            <v-text-field v-if="profile.role != 'User'" class="mb-2" type="date" v-model="mailData.date" label="Date d'envoi" />
                            <v-text-field v-if="profile.role != 'User'" class="mb-2" type="time" v-model="mailData.time" label="Heure d'envoi" />
                            <!-- subject + content -->
                            <v-text-field class="mb-2" label="Sujet" v-model="mailData.subject" />
                            <VueEditor class="mb-2" v-model="mailData.message" :editor-toolbar="toolbar"></VueEditor>
                            <!-- attachments -->
                            <div class="pt-4 f-flex flex-wrap align-center">
                                <v-btn elevation="0" @click="redirectToInput" text color="primary">
                                    <v-icon>mdi-paperclip</v-icon>
                                    <v-icon v-if="mailData.attachments.length > 0">mdi-plus</v-icon>
                                    <b class="ml-1" v-else>Ajouté une pièce jointe</b>
                                </v-btn>
                                <input type="file" multiple ref="vFileInput" class="d-none" @change="onInputChange">
                                <v-chip class="cursor-pointer ml-2" color="primary" outlined v-for="attachment, index in mailData.attachments" @click="deleteAttachment(attachment, index)">
                                    <span v-text="attachment.name"></span>
                                    <v-icon small right color="error">mdi-delete-outline</v-icon>
                                </v-chip>
                            </div>
                            <!-- urgent + response type -->
                            <v-switch v-if="profile.role !== 'User'" label="Mail urgent" v-model="mailData.urgent"></v-switch>
                            <v-select v-if="profile.role !== 'User'" class="mb-2" :items="responseTypes" item-value="value" item-text="text" v-model="mailData.responseType" label="Type de reponse"></v-select>
                            <!-- form -->
                            <v-select class="mb-2" v-if="mailData.responseType == 'form'" v-model="mailData.form" :items="forms" return-object item-text="title" label="Formulaire"></v-select>
                            <v-tooltip bottom v-if="mailData.responseType == 'form'">
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">
                                        <v-switch :label="mailData.uniqueAnswer ? 'Réponses uniques' : 'Date Limite'" class="pa-0 ma-0 mt-4" v-model="mailData.uniqueAnswer"></v-switch>
                                    </div>
                                </template>
                                <span v-if="mailData.uniqueAnswer">Une fois le formulaire validé, les réponses ne peuvent plus être modifiées.</span>
                                <span v-else>Même une fois le formulaire validé, les réponses peuvent être modifiées jusqu'à la fin du temps.</span>
                            </v-tooltip>
                            <v-col v-if="mailData.uniqueAnswer == false" class="pa-0">
                                <v-text-field type="date" v-model="mailData.deadline.date" label="Date de fin" />
                                <v-text-field type="time" v-model="mailData.deadline.time" label="Heure de fin" />
                            </v-col>

                            <!-- slideform -->
                            <span v-if="mailData.responseType === 'slideform'">
                                <v-select v-model="mailData.form" :items="slideForms" return-object item-text="name" label="Formulaire" />
                            </span>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import logger from "@/assets/functions/logger"
import notifier from "@/assets/functions/notifier"
import { deleteObject, getStorage, ref, uploadBytes } from "firebase/storage"
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { VueEditor } from "vue2-editor"

import SlideForm from '@/classes/SlideForm'
import Form from "@/classes/Form"
import Mail from "@/classes/Mail"
import Notification from "@/classes/Notification"

import DialogNewConversation from "@/components/Communication/DialogNewConversation.vue"
import UserAvatar from "@/components/UserAvatar.vue"
import UserCard from "@/components/UserCard.vue"

export default {
    name: "SendMailModal",
    props: ['profile', 'notifications', 'config', 'currentRoom', 'contactsTable'],
    components: {
        VueEditor,
        DialogNewConversation,
        UserAvatar,
        UserCard
    },
    setup() {
        return {
            storage: getStorage()
        }
    },
    data() {
        return {
            displayed: false,
            currentSender: null,
            currentReceiver: null,
            forms: [],
            slideForms: [],
            contactdisplayed: false,
            responseTypes: [
                {
                    value: "none",
                    text: "Sans reponse",
                },
                {
                    value: "text",
                    text: "Mail (text)",
                },
                {
                    value: "file",
                    text: "Piece jointe (fichier)",
                },
                {
                    value: "form",
                    text: "Formulaire",
                },
                {
                    value: "slideform",
                    text: "Formulaire V2",
                }
            ],
            mailData: {
                id: null,
                subject: "",
                message: "",
                attachments: [],
                form: null,
                responseType: "none",
                urgent: false,
                time: null,
                date: null,
                uniqueAnswer: true,
                deadline: {
                    date: null,
                    time: null
                },
                attachmentsToDelete: []
            },
            tmp_attachments_from_input: [],
            apiKey: "sc3e4lfj6m905te45946p91m7zwu3ynqm3l3fl62iknv1asv",
            datePickerDisplayed: false,
            timePickerDisplayed: false,
            unsub: [],
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote'],

                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],

                [{ 'header': [1, 2, 3, false] }],

                [{ 'align': [] }],
            ],
        }
    },
    watch: {
        tmp_attachments_from_input(newVal, oldVal) {
            if (newVal.length > 0) {
                for (const attach of newVal) {
                    this.mailData.attachments.push(attach)
                }
                this.tmp_attachments_from_input = []
            }
        }
    },
    created() {
        if (this.profile.role !== 'User') {
            this.currentReceiver = 'all'

            this.unsub.push(
                Form.listenByRoom(this.profile.currentRoom, (forms) => {
                    this.forms = forms
                })
            )
            this.unsub.push(
                SlideForm.listenByRoom(this.profile.currentRoom, data => {
                    this.slideForms = data
                })
            )
        }
    },
    methods: {
        open(mailData = null) {
            if (mailData) this.loadMailData(mailData)

            this.displayed = true
            if (!this.mailData.date) this.mailData.date = new Date().toISOString().slice(0, 10)
            if (!this.mailData.time) this.mailData.time = new Date().toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }).slice(0, 5)
        },
        close() {
            this.mailData.id = null
            this.mailData.date = null
            this.mailData.time = null
            this.mailData.subject = ""
            this.mailData.message = ""
            this.mailData.attachments = []
            this.mailData.responseType = "none"
            this.mailData.urgent = false
            this.mailData.form = null
            this.mailData.uniqueAnswer = true
            this.mailData.deadline = { date: null, time: null }
            this.mailData.attachmentsToDelete = []
            this.currentReceiver = this.profile.role != 'User' ? 'all' : null
            this.displayed = false
        },
        loadMailData(mailData){
            let tmp_data = JSON.parse(JSON.stringify(mailData))
            tmp_data.time = new Date(tmp_data.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })
            tmp_data.date = new Date(tmp_data.date).toISOString().slice(0,10)
            if(tmp_data.uniqueAnswer != true){
                tmp_data.deadline = { date: null, time: null }
                tmp_data.deadline.date = new Date(tmp_data.uniqueAnswer).toISOString().slice(0, 10)
                tmp_data.deadline.time = new Date(tmp_data.uniqueAnswer).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }).slice(0, 5)
                tmp_data.uniqueAnswer = false
            }else{
                tmp_data.deadline = { date: null, time: null }
                tmp_data.deadline.date = new Date().toISOString().slice(0, 10)
                tmp_data.deadline.time = new Date().toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }).slice(0, 5)
            }
            this.mailData = tmp_data

            this.currentReceiver = this.mailData.receiver.id ? this.contactsTable[this.mailData.receiver.id] : 'all'
            this.currentSender = this.contactsTable[this.mailData.sender.id]
        },
        async SendMail() {
            // attachments
            let oldId = JSON.parse(JSON.stringify(this.mailData.id))
            let tmp_attachments = []
            if (this.mailData.attachments.length > 0) {
                let date = new Date()
                let uploadName = date.getFullYear() + "-" + date.getMonth() + "-" + date.getDay() + "_" + date.getHours() + "h" + date.getMinutes() + "m" + date.getSeconds() + "s_" + date.getTime()
                let counter = 0
                for (let file of this.mailData.attachments) {
                    // if (file add)
                    // else (mail edit (file not moove))
                    if (file.constructor.name === 'File') {
                        let name = uploadName + "_" + counter.toString()
                        let fileRef = ref(this.storage, "mail/attachments/" + name + "." + file.name.split(".").pop())

                        await uploadBytes(fileRef, file)
                        tmp_attachments.push({
                            name: file.name,
                            ref: "mail/attachments/" + name + "." + file.name.split(".").pop(),
                        })
                        counter++
                    } else {
                        tmp_attachments.push({
                            name: file.name,
                            ref: file.ref
                        })
                    }
                }
            }
            // delete old attachments
            if (this.mailData.attachmentsToDelete) {
                const refs = this.mailData.attachmentsToDelete.map(attach => attach.ref)

                // only if no used elsewhere
                Mail.checkIfAttachIsUsed(refs, this.profile.currentRoom).then(refsUsed => {
                    for (const i in refsUsed) {
                        if (!refsUsed[i]) {
                            const fileRef = ref(this.storage, refs[i])
                            deleteObject(fileRef)
                        }
                    }
                })
            }

            // check if something is missing
            if (
                (this.profile.role != 'User' && !this.currentSender) || // sender
                (!this.currentReceiver || (this.currentReceiver != 'all' && !this.currentReceiver.id)) || // receiver
                (this.mailData.subject.trim() === '') || // subject
                (this.mailData.message.trim() === '') || // message
                (!this.currentReceiver || (this.currentReceiver != 'all' && !this.currentReceiver.uid && !this.currentReceiver.members && !this.currentReceiver.domain)) || // receiver
                (['form', 'slideform'].includes(this.mailData.responseType) && !this.mailData.form.id) || // form
                (this.mailData.responseType === 'form' && this.mailData.uniqueAnswer === false && (!this.mailData.deadline.date || !this.mailData.deadline.time)) // deadline
            ){
                Swal.fire({
                    title: "Erreur",
                    text: 'Mail incomplet.',
                    icon: "error",
                    confirmButtonText: "Ok",
                })
                return
            }

            
            // mail creation
            let new_mail = new Mail(
             /*id*/ this.mailData.id,
           /*room*/ this.profile.currentRoom,
           /*date*/ (this.mailData.date && this.mailData.time) ? new Date(this.mailData.date + " " + this.mailData.time).getTime() : tmp_date = new Date().getTime(),
  /*response type*/ this.mailData.responseType,
         /*sender*/ (this.profile.role === 'User') ? this.profile.id : this.currentSender.id,
       /*receiver*/ (this.currentReceiver === 'all') ? 'all' : (this.currentReceiver.id) ? this.currentReceiver.id : null,
         /*urgent*/ this.mailData.urgent,
          /*title*/ this.mailData.subject,
        /*content*/ this.mailData.message,
    /*attachments*/ tmp_attachments,
           /*form*/ (this.mailData.form) ? this.mailData.form.id : null,
/*show correction*/ null,
  /*unique answer*/ (this.mailData.uniqueAnswer === true) ? true : new Date(this.mailData.deadline.date + " " + this.mailData.deadline.time).getTime()
            )

            // mail saving
            await new_mail.save()

            // notifications
            let receiverType = (this.currentReceiver === 'all') ? 'all' : (this.currentReceiver.uid) ? 'profile' : (this.currentReceiver.members) ? 'group' : 'contact'
            //remove old notif
            let notifications = await Notification.getByContent(oldId)
            for(let notif of notifications) notif.delete()

            //send notif
            if (receiverType === 'all')
                notifier.send(new_mail.room, "mail", new_mail.date, new_mail.id)

            else if (receiverType === 'profile')
                notifier.sendOne(new_mail.receiver, new_mail.room, "mail", new_mail.date, new_mail.id)

            else if (receiverType === 'group')
                for (let mId of this.currentReceiver.members)
                    notifier.sendOne(mId, new_mail.room, "mail", new_mail.date, new_mail.id)

            logger.log(this.profile.id, "MAIL ADD", this.profile.email + " a ajouté un mail")
            Swal.fire({
                title: "Message envoyé",
                text: "Le message a bien été envoyé",
                icon: "success",
                confirmButtonText: "Ok",
            })
            this.close()
        },
        redirectToInput() {
            this.$refs.vFileInput.click()
        },
        onInputChange(e) {
            this.tmp_attachments_from_input = e.target.files
        },
        deleteAttachment(attachment, index) {
            this.mailData.attachments.splice(index,1)
            if (attachment.constructor.name !== 'File') {
                if (this.mailData.attachmentsToDelete === undefined) this.mailData.attachmentsToDelete = []
                this.mailData.attachmentsToDelete.push(attachment)
            }
        }
    },
    destroyed() {
        this.unsub.forEach(unsub => {
            if (typeof unsub === 'function')
                unsub()
        })
    },
}
</script>